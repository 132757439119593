@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.welcomeModalContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Poppins', sans-serif;
   font-smoothing: antialiased; }

.welcomeModalContent {
   display: flex;
   overflow: hidden;
   gap: .5rem;
   background-color: #04172D !important;
   border-radius: .5rem;
   padding: 1rem;
   max-width: 496px;
   z-index: 0; }

.backdrop {
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.667) !important;
   backdrop-filter: blur(12px) grayscale(100%);
   -webkit-backdrop-filter: blur(12px) grayscale(100%);
   opacity: 1 !important; }

.modal-header {
   padding: 0 !important;
   border: none !important;

   & > svg {
      cursor: pointer;
      transition: all 0.5s ease;
      color: rgba(255, 255, 255, 1);

      &:hover {
         color: rgba(225, 78, 10, 1); } } }

.welcomeModalBody {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   line-height: 22px;
   text-align: center;
   color: rgba(255, 255, 255, 1);

   & > img {
      width: 100%;
      height: 4rem; }

   & .bodyTitle {
      font-size: 1.5rem;
      width: 100%;
      font-weight: 800;
      line-height: 30px;
      text-align: center;
      color: rgba(225, 78, 10, 1);
      animation: breathing 4s ease-in-out infinite; }

   & .bodyContent {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 345px;
      margin: 1rem  auto; } }

.welcomeModalBkg {
   position: absolute;
   inset: 0;
   margin-top: 2.5rem;
   width: 100%;
   height: 100%;
   z-index: -1; }

@keyframes breathing {
   0% {
      transform: scale(1);
      opacity: 0.8; }
   50% {
      transform: scale(1.05);
      opacity: 1; }
   100% {
      transform: scale(1);
      opacity: 0.8; } }
