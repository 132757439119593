.overlay {
    position: absolute;
    z-index: 10;
    right: 4.6rem;
    margin-top: .6rem;
    width: 50%;
    &> span {
        margin-left: 1%;
        border-radius: 2px;
        padding: 1rem;
        padding-top: .5rem;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        height: 40px;
        background-color: white; } }

.legends {
    bottom: 1.5rem !important;
    z-index: 5 !important;
    width: fit-content !important;
    &>span {
        height: 155px; } }

.close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    z-index: 20;
    border-radius: 100px;
    background-color: rgb(155,155,155);
    opacity: .6;
    transition: opacity 1s ease;
    &:hover {
        opacity: 1; } }

.pin {
    cursor: pointer;
    border: .5px darkgrey solid;
    background-color: #17A2B8;
    color: #FFF;
    box-shadow:  0px 2px 10px 3px rgba(0, 0, 0, 0.6);
    &:hover, &.selected {
        background-color: #343A40 !important; } }

.color {
    background-color: #17A2B8;
    border-radius: 100px;
    height: 14px;
    width: 14px;
    display: inline-block; }

.other {
    background-color: #FFF; }

.device {
    background-color: #007BFF; }
.selected {
    background-color: #343A40; }
.frontier {
   background-color: #DC3545; }
.spotlight {
   background-color: #FFC107; }
.top {
    background-color: #FD7E14; }
.cluster {
    height: 32px;
    width: 32px;
    padding-left: 12px !important;
    display: flex !important;
    align-items: center;
    @media (min-width: 2560px) {
        height: 45px;
        width: 45px; }
    &.plus-10 {
        padding-left: 11px !important; }
    &.plus-100 {
        @media (min-width: 2560px) {
            padding-left: 4px !important; }
        padding-left: 5px !important; } }

.image {
    cursor: pointer;
    height: 200px;
    @media (min-width: 2560px) {
        height: 250px; }
    &>span>div {
        display: flex;
        align-items: center; }
    &>svg {
        position: absolute;
        left: calc(50% - 10px);
        top: 5px;
        height: 20px;
        width: 20px;
        @media (min-width: 2560px) {
            top: 260px;
            height: 30px;
            width: 30px; } } }

.info {
    width: 400px;
    box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.5); }
