$margin: 65px;

%link {
    transition: color 0.5s ease;
    cursor: pointer;
    position: absolute;
    color: white;
    &:hover {
        color: grey; } }

%legend {
    color: white;
    position: absolute; }

.popup-image {
    margin-left: 0 !important;
    position: fixed;
    left: $margin;
    top: 0;
    height: 100vh;
    width: calc( 100% - #{$margin} );
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.8);
    &> .col-12 {
        padding-right: 0; }
    .icon-overlay {
        @extend %link;
        position: absolute;
        left: 60px;
        bottom: 30px;
        font-size: 3rem;
        z-index: 60; }
    .icon-overlay-mobile {
        text-align: center;
        &>svg {
            @extend %link;
            position: relative;
            font-size: 3rem;
            top: 55%; } }
    .arrow {
        @extend %link;
        position: relative;
        font-size: 4rem;
        top: 50%; }
    .ReactCrop {
        top: 40px;
        max-height: 900px;
        text-align: center; }
    .close {
        @extend %link;
        top: 10px;
        z-index: 60;
        right: 1%;
        font-size: 2rem;
        stroke: black;
        stroke-width: 20;
        opacity: 1; }

    span {
        z-index: 60;
        &.loading-icon {
            position: absolute;
            left: 40%;
            top: 30%; }
        &.plate {
            @extend %legend;
            top: 30px;
            font-size: 1.5rem;
            -webkit-text-stroke: 0.5px black; }
        &.date {
            @extend %legend;
            bottom: 30px;
            font-size: 1.5rem;
            font-weight: bolder;
            -webkit-text-stroke: 0.5px black; } } }
