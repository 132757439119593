.icon-spin {
  animation: icon-spin 2s infinite linear; }

@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.link {
    transition: color 0.5s ease;
    color: #000 !important;
    cursor: pointer;
    text-decoration: none !important;

    &:hover {
        color: grey !important; } }

.btn {
  min-width: 100px; }

.hidden-sm-down {
  @media screen and (max-width: 768px) {
    display: none !important; } }

.hidden {
  display: none; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.color-black {
  color: black; }

.carousel-status {
  font-size: 12px !important;
  background-color: rgba(0, 0, 0, .4);
  right: calc(50% - 20px) !important;
  top: 155px !important;
  @media (min-width: 2560px) {
    top: 255px !important; } }

.info-map {
  .carousel-status {
    top: 170px !important;
    @media (min-width: 2560px) {
      top: 220px !important; } } }

.rs-picker-menu {
  z-index: 50 !important; }

$brand-color: #FF7200;

.cbd-date input:focus {
    border-color: $brand-color !important; }

div[role=presentation] {
  & h2 {
    color: $brand-color; }
  & svg {
    color: $brand-color;
    &:hover {
      color: lighten($brand-color, 15%); } }
  & div::after {
    color: $brand-color; }
  & button {
    &[data-selected=true] {
      background-color: $brand-color; }
    &:hover {
      border-color: darken($brand-color, 2%); } }
  & input[type=text] {
    color: $brand-color;
    &::placeholder {
      color: $brand-color; }
    &+span {
      color: $brand-color; } }
  & #time-picker_down-button, #time-picker_up-button, {
    color: $brand-color; }
  & #time-picker_cancel-button {
    color: $brand-color;
    &:focus {
      border: .5px solid $brand-color; } }
  & #time-picker_ok-button {
    background-color: darken($brand-color, 2%);
    border-color: $brand-color; } }
.kkFmmY {
  background-color: $brand-color !important;
  color: #fff !important;
  &::placeholder {
    color: #fff !important; } }
