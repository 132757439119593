%link {
    transition: color 0.5s ease;
    cursor: pointer;
    position: absolute;
    color: white;
    &:hover {
        color: grey; } }

%upside {
    top: -30px;
    z-index: 10000;
    font-size: 2rem;
    stroke: black;
    stroke-width: 20;
    opacity: 1; }
.loading-icon {
    z-index: 10000;
    position: absolute;
    left: 40%;
    top: 30%; }

.popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8);
    .arrow {
        @extend %link;
        position: relative;
        font-size: 4rem;
        top: 50%; }
    .close {
        @extend %link;
        @extend %upside;
        right: 0; }
    .toolbar {
        @extend %upside;
        position: absolute;
        .disabled {
            pointer-events: none;
            svg {
                color: grey; } }
        svg {
            @extend %link;
            position: relative;
            float: left;
            margin-right: 10px;
            font-size: 1.7rem; } } }

.no-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
