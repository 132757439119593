@charset "UTF-8";
.rc-switch {
  background-color: #d9d9d9;
  border: 0;
  padding: 2px;
  width: 36px;
  display: flex;
  float: right; }
  .rc-switch.rc-switch-checked {
    background-color: #FF7200; }
    .rc-switch.rc-switch-checked:after {
      left: 50%;
      content: "✓";
      color: #FF7200; }
  .rc-switch:after {
    display: flex;
    position: relative;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    box-shadow: none;
    left: 0;
    content: "✕";
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #d9d9d9;
    font-weight: bold; }
  .rc-switch:focus {
    outline: none; }
