.table-wapper {
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px; }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; }

    &::-webkit-scrollbar-thumb {
        background: #888;
        &:hover {
            background: #555; } } }
