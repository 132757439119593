@font-face {
  font-family: 'plate';
  src: url("../../assets/fonts/fe-font.ttf") format("truetype"); }

@font-face {
  font-family: 'old-plate';
  src: url("../../assets/fonts/mandatory.otf") format("truetype"); }

@font-face {
  font-family: 'Futura Heavy';
  src: url("../../assets/fonts/Futura Heavy.otf") format("truetype"); }

%plate-default {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  width: max-content;
  text-align: center;
  font-size: 1rem;
  height: auto;
  letter-spacing: 8px;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    font-size: 1rem; }
  @media (min-width: 1366px) {
    font-size: 1.3rem; }
  @media (min-width: 1600px) {
    font-size: 2rem; }
  @media (min-width: 1920px) {
    font-size: 2.5rem; } }

.plate {
  @extend %plate-default;
  background-image: url("../../assets/plate-background.png");
  font-weight: 500;
  font-family: "plate";
  padding: 25px 10px 15px;

  @media (min-width: 1280px) {
    padding: 20px 5px 10px; }
  @media (min-width: 1366px) {
    padding: 20px 10px 10px; }
  @media (min-width: 1600px) {
    padding: 25px 10px 15px; }
  @media (min-width: 1920px) {
    padding: 20px 10px 15px; } }

.old {
  @extend %plate-default;
  background-image: url("../../assets/plate-old-background.png");
  font-family: "old-plate";
  font-weight: 800;
  padding: 25px 10px 15px;

  @media (min-width: 1280px) {
    padding: 20px 10px 10px; }
  @media (min-width: 1366px) {
    padding: 20px 10px 10px; }
  @media (min-width: 1600px) {
    padding: 25px 10px 15px; } }

