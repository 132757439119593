.downloader {
    position: absolute;
    width: 30%;
    right: 50px;
    bottom: 0;
    z-index: 100; }
.card {
    box-shadow: 4px -2px 7px 0px lightgrey;
    svg {
        cursor: pointer; } }
.body {
    padding: 1px 0 2px 0 !important;
    height: 300px !important;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 100%; }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; }

    &::-webkit-scrollbar-thumb {
        background: #888;
        &:hover {
            background: #555; } } }
