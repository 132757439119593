.close {
   position: absolute;
   top: 0;
   right: 5px;
   z-index: 1;

   &.badge {
      top: 0px;
      right: 8px;
      width: 18px;
      height: 18px;
      padding: 2px 5px;
      font-size: 11px;
      line-height: 14px;
      border-radius: 9px;
      text-align: center;
      color: white; } }
