%date-time-inputs {
  transition: width 0.5s ease;
  height: calc(2.25rem + 2px);
  padding-left: 0.375rem;
  padding-top: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative; }

// Mixins
@mixin form-control-base {
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #fff; }

@mixin focus-state {
  border-color: rgba(255, 114, 0, .75);
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(255, 114, 0, .25);

  &.is-invalid {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); } }

@mixin invalid-state {
  border-color: #DC3545; }

@mixin disabled-state {
  background-color: #e3e3e3;
  opacity: 0.65;
  cursor: not-allowed; }

// Common styles
.form-control-label {
  display: flex;
  align-items: center;
  gap: 0.5rem; }

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

// Date Picker
.date-input {
  position: relative;

  .react-date-picker {
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid #CED4DA;

    &__wrapper {
      width: 100%;
      border: 1px solid transparent;
      border-radius: 0.375rem;
      padding: 0.375rem 0.75rem;

      input {
        border: none;
        outline: none;
        &:focus {
          outline: none;
          box-shadow: none; } } }

    &:focus-within {
      @include focus-state; }

    &.is-invalid {
      @include invalid-state; }

    &.is-disabled {
      @include disabled-state; }

    &__calendar {
      .react-calendar {
        border-radius: .5rem;
        border: 1px solid #CED4DA;
        overflow: hidden;

        &__navigation {
          button {
            color: #FF7200;
            font-weight: bold;
            font-size: 1.25rer;

            @media screen and (max-width: 768px) {
              font-size: .8rem; }

            &:not(:disabled):hover {
              background-color: #FF7200;
              color: white; }

            &:disabled {
              opacity: 0.3;
              cursor: not-allowed; } } }

        &__tile {
          &--now {
            background-color: rgba(255, 115, 0, 0.448);
            font-weight: bold; }

          &:not(:disabled):hover {
            background-color: rgba(255, 114, 0, .25); }

          &--active {
            background-color: rgb(255, 114, 0);
            color: white; }

          &.react-calendar__tile--active:hover {
            background-color: rgb(255, 114, 0);
            color: white; } } } } } }

// Date Picker Old Version (será removido em breve)
.date-input-old {
  @extend %date-time-inputs;
  width: 100%;

  .react-date-picker__wrapper {
    transition: width 0.5s ease;
    border: 0;
    width: 100px; }

  .react-date-picker__inputGroup__leadingZero {
    margin: auto; }

  .react-time-picker__wrapper {
    transition: width 0.5s ease;
    border: 0 !important;
    width: 55px; }

  .react-time-picker__inputGroup {
    min-width: 50px; }

  .react-date-picker__calendar {
    z-index: 100; }

  &.is-open {
    & .react-date-picker {
      width: 30%; } }

  &.disabled {
    background-color: #e3e3e3;
    .react-date-picker__inputGroup {
      background-color: #e3e3e3; } }

  &.is-invalid {
    border-color: #dc3545;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em*4);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); } }

// Time Picker
.time-input {
  position: relative;

  input[type="time"] {
    @include form-control-base;

    &:focus-within {
      @include focus-state; }

    &.is-invalid {
      @include invalid-state; }

    &.is-disabled {
      @include disabled-state; } } }

// Time Picker Old Version (será removido em breve)
.time-input-old {
  @extend %date-time-inputs;
  padding: 0.375rem 0.4rem;
  width: 100%;

  .react-time-picker__wrapper {
    transition: width 0.5s ease;
    border: 0;
    width: 55px; }

  &.is-invalid {
    border-color: #dc3545;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em*4);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); } }
