.overlay {
    z-index: 10;
    cursor: pointer;
    transition: color 0.5s ease;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 40%;
    color: lightgrey;
    &:hover {
        color: black; } }

.previous {
    left: 5px; }

.next {
    right: 5px; }

.formatter {
    left: 45%; }
