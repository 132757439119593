@import '../../styles/_variables';

header ul {
    position: absolute;
    right: 1rem; }

.navbar {
    display: flex;
    max-height: 100vh;
    overflow: hidden auto;
    height: $header-height;
    @media screen and (max-width: 768px) {
        height: auto; }
    &::-webkit-scrollbar {
        display: none; } }

.profile {
    @media screen and (min-width: 769px) {
        position: absolute;
        right: 0;
        bottom: 14px;
        margin-right: 10px;
        padding-right: .5rem;
        & svg {
            color: #E14E0A; }
        & span {
            color: white; } } }
