.tab {
    cursor: pointer;
    transition: color 0.2s ease;

    & > a {
        color: #505356;

        &.active {
            color: #FF7200 !important;
            font-weight: 700; }

        &:not(.active):hover {
            color: #a94f06;
            border: 1px solid transparent; }
        &.error {
            color: red !important;
            font-weight: 700; } } }
