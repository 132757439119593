.drop-zone {
    border: 1px solid lightgrey;
    padding: 10px 15px;
    cursor: pointer;
    transition: border 0.5s ease;
    p {
        display: inline;
        vertical-align: middle; }
    &:hover {
        border: 1px solid grey; }
    &.active {
        border: 1px solid grey; }
    &.reject {
        border: 1px solid red; } }

.file-list {
    font-weight: bolder;
    position: fixed !important;
    z-index: 10;
    width: 300px;
    left: 10px;
    opacity: 0.4;
    max-height: 165px;
    overflow-x: hidden;
    transition: opacity 0.5s ease;
    &:hover {
        opacity: 1; }
    .row {
        margin-bottom: 5px; } }


