.animated {
    animation: falling 1.5s ease alternate infinite; }

@keyframes falling {
    0% {
        transform: translateY(-50%);
        opacity: 0; }
    50% {
        transform: translateY(0%);
        opacity: 1; }
    100% {
        transform: translateY(50%);
        opacity: 0; } }
