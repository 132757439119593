@import './_variables';

.sidebar {
    height: calc( 100vh - #{$header-height} );
    z-index: 1000;
    position: fixed;
    width: $collapsed-width;
    transition: width .1s linear;
    overflow: hidden auto;
    align-items: center;
    padding: 1.5rem 0;

    &.continue::after {
        content: '';
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        max-width: $collapsed-width;
        height: 20px; //* Altura do degradê, ajuste conforme necessário */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .1), rgba(255, 255, 255, .2), rgba(255, 255, 255, .6)); //* Gradiente transparente para branco */
        pointer-events: none; //* Garante que o pseudo-elemento não interfira com eventos de mouse */
        transition: max-width .1s linear; }
    &::-webkit-scrollbar {
        display: none; }

    &>ul {
        width: 100%;
        padding-right: 10px;
        height: calc(100% - 43px);
        overflow: auto;
        width: 100%;
        padding: 0 .5rem;

        &::-webkit-scrollbar {
            display: none; }

        &>li {
            border-bottom: 1px solid transparent;

            &:not(:last-child):hover {
                border-bottom: 1px solid rgba(211,211,211, .2); }

            &:last-child {
                position: absolute;
                border-top: 1px solid rgba(211,211,211, .3);
                bottom: 0;
                width: 100%;
                max-width: $collapsed-width !important;
                transition: max-width 0.1s linear;
                background-color: #08142c; } } }

    & a {
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        gap: 1.2rem;
        padding-left: .5rem;
        align-items: center;
        width: 100%;
        position: relative;

        svg {
            min-width: 20px; }

        &>div {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &>span {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.5s linear; } } }

    &:hover,
    &.expanded {
        width: $width;

        &.continue::after {
            max-width: $width; }

        & li:last-child {
            max-width: $width !important; }

        & a>div>span {
            visibility: visible;
            opacity: 1; } }

    & li {
        &.header {
            &>.submenu {
                will-change: display;
                display: none;
                &>li {
                    visibility: hidden !important; } }

            &.open>.submenu {
                display: contents; } } }

    & + main {
        margin-left: $collapsed-width;
        @media screen and (max-width: 768px) {
            margin-left: auto; }
        max-height: calc(100vh - #{$header-height});
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 10px;
            height: calc(100vh - 100px); }
        &::-webkit-scrollbar-track {
            background-color: #f1f1f1; }
        &::-webkit-scrollbar-thumb {
            background-color: #888;
            &:hover {
                background-color: #555; } } } }
