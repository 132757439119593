.preview {
    height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px; } }

.preview, .table {
    &::-webkit-scrollbar-track {
        background: #f1f1f1; }

    &::-webkit-scrollbar-thumb {
        background: #888;
        &:hover {
            background: #555; } } }

.table {
    th {
        position: sticky;
        top: 0; }
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
        height: 10px; } }
