.container {
    position: relative;
    z-index: 99999;
    width: 100%; }

.content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: .5rem;
    &.popup {
        width: fit-content;
        margin-left: 1rem;
        margin-top: 1rem; }
    &.reverse {
        flex-direction: row-reverse; } }

.dropdownWrapper {
    position: relative;
    display: flex;
    width: 24px;
    align-items: center;
    justify-content: flex-end; }

.starIcon {
    cursor: pointer;
    z-index: 99999; }

.dropdown {
    position: absolute; }

.dropdownMenu {
    position: absolute !important;
    z-index: 99999 !important;
    height: 16rem;
    top: 1rem !important;
    overflow-y: auto;
    left: -1.5rem !important;
    &::-webkit-scrollbar {
        width: 10px;
        height: calc(100vh - 100px); }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; }

    &::-webkit-scrollbar-thumb {
        background: #888;
        &:hover {
            background: #555; } } }

.dropdownItem {
    cursor: pointer; }

.tagBadge {
    text-transform: uppercase;
    font-size: 0.6rem;

    @media (max-width: 1280px) {
        font-size: 10px; } }

.hiddenToggle {
    display: none;
    position: absolute; }

.dropdown-toggle {
    position: relative;
    left: 100px;
    top: 20px; }

.popup {
    display: flex;
    position: absolute; }

.dropdownContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%; }

.dropdown {
    align-items: center;
    justify-content: center;
    position: absolute; }

.icon-overlay {
    position: relative;
    z-index: 99999 !important; }

.tooltip {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.6rem;
    z-index: 99999 !important; }
