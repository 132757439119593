@font-face {
  font-family: 'password';
  src: url("../../../assets/fonts/password.ttf") format("truetype"); }

$primary: #E14E0A;
$dark: #04172D;

@mixin focus {
  outline: none;
  border-radius: .5rem;
  box-shadow: 0 0 0 2px rgba(255, 115, 0, 0.559); }

%input {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 1rem;
  font-family: 'Arial';
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  background-image: none !important;

  &::placeholder {
    color: #7B7B7B; } }

.togglerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a {
    display: flex;
    margin-top: -.5rem;
    align-self: flex-end;
    color: $dark;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:focus {
      @include focus;
      color: $primary; } } }

.input-password {
  @extend %input;
  font-family: 'password';

  &::placeholder {
    font-family: 'Arial'; } }

.toggleButton {
  position: absolute;
  right: 8px;
  top: 6px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:focus {
    @include focus; } }
